import { LabeledValue } from 'aqTypes';
import {
  Entry,
  EntryValue,
  GroupEntryProps,
  ReferenceGroup,
  SingleEntryProps,
} from './types';
import { APIReferenceType, ArdoqId } from '@ardoq/api-types';
import { referenceInterface } from '@ardoq/reference-interface';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

export const groupReferencesBySourceAndType = (
  accumulator: ReferenceGroup,
  referenceId: ArdoqId
): ReferenceGroup => {
  const sourceComponentId =
    referenceInterface.getSourceComponentId(referenceId);
  const sourceWorkspaceId =
    sourceComponentId && componentInterface.getWorkspaceId(sourceComponentId);

  const referenceTypeName = referenceInterface.getModelType(referenceId)?.name;

  const key = `${sourceWorkspaceId}-${referenceTypeName}`;

  return {
    ...accumulator,
    [key]: [...(accumulator[key] || []), referenceId],
  };
};

const singleEntryPropsGetter = ([
  referenceId,
]: ArdoqId[]): SingleEntryProps | null => {
  const sourceComponentId =
    referenceInterface.getSourceComponentId(referenceId);
  const targetComponentId =
    referenceInterface.getTargetComponentId(referenceId);
  const sourceComponentName =
    sourceComponentId && componentInterface.getDisplayName(sourceComponentId);
  const targetComponentName =
    targetComponentId && componentInterface.getDisplayName(targetComponentId);
  const referenceType = referenceInterface.getModelType(referenceId)?.name;

  if (!sourceComponentName || !targetComponentName || !referenceType) {
    return null;
  }

  return {
    sourceComponentName,
    targetComponentName,
    referenceType,
  };
};

const groupEntryPropsGetter = (
  referenceIds: ArdoqId[]
): GroupEntryProps | null => {
  const sourceComponentId = referenceInterface.getSourceComponentId(
    referenceIds[0]
  );
  const sourceWorkspaceId =
    sourceComponentId && componentInterface.getWorkspaceId(sourceComponentId);
  const sourceWorkspaceName =
    sourceWorkspaceId && workspaceInterface.getWorkspaceName(sourceWorkspaceId);
  const referenceType = referenceInterface.getModelType(referenceIds[0])?.name;

  if (!referenceType || !sourceWorkspaceName) {
    return null;
  }

  return {
    sourceWorkspaceName,
    referenceType,
    numberOfReferences: referenceIds.length,
  };
};

export const prepareEntriesWithProps = (
  referenceIds: ArdoqId[]
): Entry | null => {
  const props =
    referenceIds.length === 1
      ? singleEntryPropsGetter(referenceIds)
      : groupEntryPropsGetter(referenceIds);

  const validOptions = getReferenceTypeOptions(referenceIds[0]);

  if (!props || !validOptions) {
    return null;
  }

  return {
    validOptions,
    referenceIds,
    props,
  };
};

type PartitionedReference = {
  id: ArdoqId;
  /** If the reference being reversed has the same reference type in both
   * workspaces, this value will be the ref. type ID in the (current) target
   * workspace. All unsafe references have `null` for this attribute, while all
   * safe have `string | number`. */
  equivalentTypeID: string | number | null;
};

export type PartitionSafeAndUnsafeReferencesPureOpts = {
  sourceWorkspaceId: string | null;
  currentTypeID: number | null;
  targetWorkspaceId: string | null;
  referenceTypeName: string | undefined;
  targetWsReferenceTypes: Record<string | number, APIReferenceType> | null;
};
export const partitionSafeAndUnsafeReferencesPure = (
  [safe, unsafe]: [PartitionedReference[], PartitionedReference[]],
  referenceId: ArdoqId,
  {
    sourceWorkspaceId,
    currentTypeID,
    targetWorkspaceId,
    referenceTypeName,
    targetWsReferenceTypes,
  }: PartitionSafeAndUnsafeReferencesPureOpts
): [safe: PartitionedReference[], unsafe: PartitionedReference[]] => {
  if (!targetWsReferenceTypes || !currentTypeID) {
    return [safe, unsafe];
  }

  if (sourceWorkspaceId === targetWorkspaceId) {
    return [
      [...safe, { id: referenceId, equivalentTypeID: currentTypeID }],
      unsafe,
    ];
  }

  const equallyNamedTypeID = Object.keys(targetWsReferenceTypes).find(
    key => targetWsReferenceTypes[key].name === referenceTypeName
  );

  if (equallyNamedTypeID) {
    return [
      [...safe, { id: referenceId, equivalentTypeID: equallyNamedTypeID }],
      unsafe,
    ];
  }
  return [safe, [...unsafe, { id: referenceId, equivalentTypeID: null }]];
};

// A reference is termed safe if target workspace includes the reference type name
export const partitionSafeAndUnsafeReferences = (
  partitioned: [PartitionedReference[], PartitionedReference[]],
  referenceId: ArdoqId
) => {
  const sourceComponentId =
    referenceInterface.getSourceComponentId(referenceId);
  const sourceWorkspaceId =
    sourceComponentId && componentInterface.getWorkspaceId(sourceComponentId);
  const currentTypeID = referenceInterface.getTypeId(referenceId);

  const targetComponentId =
    referenceInterface.getTargetComponentId(referenceId);
  const targetWorkspaceId =
    targetComponentId && componentInterface.getWorkspaceId(targetComponentId);

  const referenceTypeName = referenceInterface.getModelType(referenceId)?.name;
  const targetWsReferenceTypes = targetWorkspaceId
    ? workspaceInterface.getReferenceTypes(targetWorkspaceId)
    : null;

  return partitionSafeAndUnsafeReferencesPure(partitioned, referenceId, {
    currentTypeID,
    referenceTypeName,
    sourceWorkspaceId,
    targetWorkspaceId,
    targetWsReferenceTypes,
  });
};

const getReferenceTypeOptions = (
  referenceId: ArdoqId
): LabeledValue[] | null => {
  const targetComponentId =
    referenceInterface.getTargetComponentId(referenceId);
  const targetWorkspaceId =
    targetComponentId && componentInterface.getWorkspaceId(targetComponentId);

  const targetReferenceTypes =
    targetWorkspaceId &&
    workspaceInterface.getReferenceTypes(targetWorkspaceId);

  if (!targetReferenceTypes) {
    return null;
  }

  const unselectedReferenceType = { value: null, label: 'Unselected' };

  return [
    unselectedReferenceType,
    ...Object.values(targetReferenceTypes).map((val: any) => ({
      value: val.id,
      label: val.name,
    })),
  ];
};

export const isGroupEntryProps = (
  entryProps: GroupEntryProps | SingleEntryProps
): entryProps is GroupEntryProps => {
  return (entryProps as GroupEntryProps).numberOfReferences !== undefined;
};

export const getIntersectionOfReferenceOptions = (
  entries: Entry[]
): LabeledValue[] => {
  const referenceOptions = entries.map(e => e.validOptions.map(o => o.label));
  return referenceOptions[0]
    .filter(value => referenceOptions.every(e => e.includes(value)))
    .map((label, value) => ({
      label,
      value,
    }));
};

export const getEntryValues = (
  entries: Entry[],
  label?: string
): EntryValue[] =>
  entries.map(e => ({
    referenceIds: e.referenceIds,
    typeValue: label
      ? e.validOptions.find(e => e.label === label)!.value
      : null,
  }));
